import {
  React,
  bind,
  _,
  numeral,
  moment
} from "$Imports/Imports";

import {
  Customer,
  CustomerContact,
  CustomerSource,
  Employee,
  Quote,
  SalesHistoryReportParameters
} from "$Generated/api";

import {
  CardActions,
  GridColDef,
  DataGridPro,
  GridRenderCellParams,
  GridValueGetterParams,
  IconButton,
  Button,
  GridValueFormatterParams,
  GridApiPro
} from "$Imports/MaterialUIComponents";

import {
  ContactPage,
  Download
} from "$Imports/MaterialUIIcons";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  CustomerLink,
  DisplayFormattedNumber,
  DisplayFormattedDatetime,
  QuoteLink,
  AddCustomerContactInfoModal
} from "$Imports/CommonComponents";

import {
  ReportService,
  IReportServiceInjectedProps
} from "$State/ReportFreezerService";

import {
  IStateServiceInjectedProps,
  StateService
} from "$State/RegionFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  SalesHistorySearchForm
} from "./SalesHistorySearchForm";

import {
  CURRENCY_NO_DECIMAL_FORMAT,
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  OnExportCsvClick
} from "$Utilities/functionUtil";

const styles: {
  mainContainer: string,
  filterContainer: string
} = require("./SalesHistory.scss");

interface ISalesHistoryViewState {
  isCustomerNoteModalOpen: boolean;
  noteCustomer?: Customer;
}

interface ISalesHistoryViewBaseProps {
  companyId: number | undefined;
}

type ISalesHistoryViewProps = ISalesHistoryViewBaseProps
  & IReportServiceInjectedProps
  & IStateServiceInjectedProps
  & IEmployeeServiceInjectedProps;

class _SalesHistoryView extends React.Component<ISalesHistoryViewProps, ISalesHistoryViewState> {

  state: ISalesHistoryViewState = {
    isCustomerNoteModalOpen: false
  };

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "Customer Name",
      field: "customerName",
      flex: 3,
      valueFormatter: (params: GridValueFormatterParams<string | undefined>) => params.value ?? "",
      renderCell: (params: GridRenderCellParams<string | undefined, Customer>) => {
        return (
          <CustomerLink
            customerId={params.row.id}
            customerName={params.value}
          />
        );
      }
    },
    {
      headerName: "City",
      field: "city",
      flex: 2
    },
    {
      headerName: "State",
      field: "region",
      valueGetter: (params: GridValueGetterParams<string | undefined, Customer>) => params.row.region?.regionAbbreviation ?? "",
      flex: 1
    },
    {
      headerName: "",
      field: "customerContacts",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      minWidth: 24,
      width: 24,
      disableExport: true,
      renderCell: (params: GridRenderCellParams<Array<CustomerContact> | undefined, Customer>) => {
        return (
          <IconButton onClick={() => this._openCustomerNoteModal(params.row)}>
            <ContactPage />
          </IconButton>
        )
      }
    },
    {
      headerName: "Email",
      field: "emailAddress",
      sortable: false,
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<string | undefined>) => params.value ?? "",
      renderCell: (params: GridRenderCellParams<string | undefined>) => {
        return params.value ? <a href={`mailto:${params.value}`} target="_blank">{params.value}</a> : "";
      }
    },
    {
      headerName: "Source",
      field: "customerSource",
      flex: 2,
      valueGetter: (params: GridValueGetterParams<CustomerSource | undefined>) => {
        return params.value?.name ?? "Unknown";
      }
    },
    {
      headerName: "Active?",
      field: "isActive",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<boolean | undefined>) => {
        return params.value ? "Yes" : "No";
      }
    },
    {
      headerName: "Account Manager",
      field: "accountManager",
      flex: 3,
      valueGetter: (params: GridValueGetterParams<Employee | undefined>) => {
        return `${params.value?.firstName ?? ""} ${params.value?.lastName ?? ""}`;
      }
    },
    {
      headerName: "Quote Count",
      field: "quotes",
      flex: 2,
      valueGetter: (params: GridValueGetterParams<Array<Quote> | undefined>) => {
        return params.value?.length ?? 0;
      }
    },
    {
      headerName: "Quote Amount",
      field: "quoteAmount",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<number>) => numeral(params.value).format(CURRENCY_NO_DECIMAL_FORMAT),
      valueGetter: (params: GridValueGetterParams<undefined, Customer>) => _.sumBy(params.row.quotes, q => q.negotiatedRate ?? 0),
      renderCell: (params: GridRenderCellParams<number, Customer>) => <DisplayFormattedNumber value={params.value} formatString={CURRENCY_NO_DECIMAL_FORMAT} />,
    },
    {
      headerName: "Last Quote Date",
      field: "lastQuoteDate",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<Date>) => moment(params.value).local().format(DATE_ONLY_FORMAT),
      valueGetter: (params: GridValueGetterParams<undefined, Customer>) =>_.first(params.row.quotes)?.quoteDate,
      renderCell: (params: GridRenderCellParams<Date, Customer>) =>
        <DisplayFormattedDatetime
          value={params.value}
          formatString={DATE_ONLY_FORMAT}
        />
    },
    {
      headerName: "Last Quote #",
      field: "lastQuoteNum",
      flex: 2,
      valueFormatter: (params: GridValueFormatterParams<number>) => `Q${params.value}`,
      valueGetter: (params: GridValueGetterParams<undefined, Customer>) => {
        return _.first(params.row.quotes)?.quoteNumber; // for sorting
      },
      renderCell: (params: GridRenderCellParams<Quote, Customer>) => {
        const firstQuote = _.first(params.row.quotes);
        return (
          <QuoteLink
            quoteId={firstQuote?.id}
            quoteNumber={`Q${firstQuote?.quoteNumber}`}
            isCustomerQuote={false}
          />
        )
      }
    }
  ];

  componentDidMount() {
    this.props.employeeService.fetchAccOrBusDevManagers();
    this.props.regionService.fetchStates();
  }

  @bind
  private _onSearchClick(criteria: SalesHistoryReportParameters) {
    this.props.reportService.fetchSalesHistoryReport(criteria);
  }

  @bind
  private _openCustomerNoteModal(selectedCustomer: Customer) {
    this.setState({
      isCustomerNoteModalOpen: true,
      noteCustomer: selectedCustomer
    });
  }

  @bind
  private _closeCustomerNoteModal() {
    this.setState({
      isCustomerNoteModalOpen: false,
      noteCustomer: undefined
    });
  }

  private _gridApiRef: React.MutableRefObject<GridApiPro> = { current: {} as any };

  render() {
    const {
      isCustomerNoteModalOpen,
      noteCustomer
    } = this.state;

    const {
      salesHistoryParameters,
      salesHistoryReportFetchResults
    } = this.props.reportService.getState();
    const reportData = salesHistoryReportFetchResults.data ?? [];

    const {
      accOrBusDevManagerFetchResults
    } = this.props.employeeService.getState();
    const accOrBusDevManagers = accOrBusDevManagerFetchResults.data ?? [];

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regionData = regionFetchResults.data ?? [];

    return (
      <>
        <div className={styles.mainContainer}>
          <CardLinedHeader
          titleText="Customer Sales History"
          style={{ flex: "1" }}
          titleComponents={
            <Button
              className="cardHeaderButton"
              onClick={() => OnExportCsvClick('Customer_Sales_Report_', this._gridApiRef)}
              title="Download"
              disabled={reportData.length === 0}
            >
              <Download /><b> Download</b>
            </Button>
          }
        >
            <CardActions>
              <SalesHistorySearchForm
                criteria={salesHistoryParameters}
                salesReps={accOrBusDevManagers}
                regions={regionData}
                onSubmit={this._onSearchClick}
              />
            </CardActions>
            <AjaxActionIndicator state={[salesHistoryReportFetchResults]} />
            <div>
              <DataGridPro
                columns={this.gridColumns}
                rows={reportData}
                density="compact"
                initialState={{
                  sorting: { sortModel: [{ field: "quoteAmount", sort: "desc" }] }
                }}
                apiRef={this._gridApiRef}
                disableMultipleSelection
                hideFooter
                autoHeight
              />
            </div>
          </CardLinedHeader>
        </div>
        <AddCustomerContactInfoModal
          isOpen={isCustomerNoteModalOpen}
          customer={noteCustomer}
          onClose={this._closeCustomerNoteModal}
        />
      </>
    )
  }
}

export const SalesHistoryView = ReportService.inject(
  StateService.inject(
    EmployeeService.inject(
      _SalesHistoryView
    )
  )
);