import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  moment,
  NullableOptional,
  Freezer
} from "$Imports/Imports";

import {
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import
  yup
from "$Shared/utilities/yupExtension";

import {
  ReportApiFactory,
  BookedSalesReport,
  BookedSalesFilters,
  TmwinCompanyInfo,
  TmwinTerminal,
  TlorderSalesAgent,
  LaneRevenueReportView,
  LaneRevenueParametersVM,
  TripDetailsLegView,
  OpportunitySearchCriteria,
  Opportunity,
  OpportunitySearchCriteriaDateTypeEnum,
  SalesHistoryReportParameters,
  Customer,
  ReviewSearchCriteria,
  QuoteReviewsReportRow,
  ReviewSearchCriteriaDateTypeEnum,
  ReviewSearchCriteriaReviewTypeEnum,
  ConversionSearchCriteria,
  QuoteConversionReportRow,
  DeliveredFreightSearchCriteria,
  DeliveredFreightReportView,
  DeliveredFreightReport
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

export interface IBookedSalesReportFilter {
  startDate?: Date;
  endDate?: Date;
}

interface ICheckboxFilter {
  id?: number
  displayName?: string,
  isChecked?: boolean
}

export interface ISalesOpportunitiesFilters {
  companyKey: string,
  customers: ICheckboxFilter[],
  salesReps: ICheckboxFilter[],
  leadSources: ICheckboxFilter[]
}

export const BookedSalesReportFilterValidationSchema: SchemaOf<IBookedSalesReportFilter> = yup.object({
  startDate: yup.date()
    .typeError("Invalid Date")
    .required("From Date is required to perform a search"),
  endDate: yup.date()
    .typeError("Invalid Date")
    .notRequired()
    .when('startDate', (fromDate: Date, schema: any) => {
      return fromDate ? schema.min(fromDate, "The To Date must be after the From Date")
        : schema.notRequired();
    })
});

export const QuoteConversionReportCriteriaValidationSchema: SchemaOf<NullableOptional<ConversionSearchCriteria>> = yup.object({
  companyId: yup.number().notRequired(),
  startDate: yup.date()
    .typeError("Invalid date")
    .required("From date is required"),
  endDate: yup.date()
    .typeError("Invalid date")
    .required("To date is required")
    .mustBeAfter("startDate", "To date must be after From date")
    .when('startDate', (startDate: Date, schema: any) => {
      return schema.max(moment(startDate).add(1, "years").startOf("day").toDate(), "Date range cannot be longer than 1 year");
    })
});

export const QuoteReviewsReportCriteriaValidationSchema: SchemaOf<NullableOptional<ReviewSearchCriteria>> = yup.object({
  companyId: yup.number().notRequired(),
  reviewedByIds: yup.array().notRequired(),
  createdByIds: yup.array().notRequired(),
  reviewType: yup.mixed<ReviewSearchCriteriaReviewTypeEnum>().notRequired(),
  dateType: yup.mixed<ReviewSearchCriteriaDateTypeEnum>().required(),
  startDate: yup.date()
    .typeError("Invalid date")
    .required("From date is required"),
  endDate: yup.date()
    .typeError("Invalid date")
    .required("To date is required")
    .mustBeAfter("startDate", "To date must be after From date")
    .when('startDate', (startDate: Date, schema: any) => {
      return schema.max(moment(startDate).add(1, "years").startOf("day").toDate(), "Date range cannot be longer than 1 year");
    })
});

export const DeliveredFreightReportSearchCriteriaValidationSchema: SchemaOf<NullableOptional<DeliveredFreightSearchCriteria>> = yup.object({
  companyId: yup.number().notRequired(),
  startDate: yup.date()
    .typeError("Invalid date")
    .required("From date is required"),
  endDate: yup.date()
    .typeError("Invalid date")
    .required("To date is required")
    .mustBeAfter("startDate", "To date must be after From date")
    .when('startDate', (startDate: Date, schema: any) => {
      return schema.max(moment(startDate).add(1, "years").startOf("day").toDate(), "Date range cannot be longer than 1 year");
    }),
  sortColumn: yup.string().notRequired().allowEmpty(),
  sortAscending: yup.boolean().notRequired(),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired(),
});

export const LaneRevenueReportCriteriaValidationSchema: SchemaOf<LaneRevenueParametersVM> = yup.object({
  originProvince: yup.string().notRequired(),
  destinationProvince: yup.string()
    .when('originProvince', (originProvince: string, schema: any) => {
      return !originProvince ? schema.required("Specific location must be selected for origin or destination") : schema.notRequired();
    }),
  companyId: yup.number().notRequired(),
  startDate: yup.date()
    .typeError("Invalid date")
    .required("From is required"),
  endDate: yup.date()
    .typeError("Invalid date")
    .required("To is required")
    .test("endDate", "${message}", (value: Date | undefined, testContext: any) => {
      const startDate = moment(testContext.parent.startDate, 'day');
      const endDate = moment(value);

      if (endDate.isBefore(startDate)) {
        return testContext.createError({ message: "To must be after From" });
      }

      if (endDate.diff(startDate, 'years') > 0) {
        return testContext.createError({ message: "Date range cannot exceed one year" });
      }

      return true;
    }),
});

export const OpportunityReportCriteriaValidationSchema: SchemaOf<NullableOptional<OpportunitySearchCriteria>> = yup.object({
  customerId: yup.number().notRequired(),
  customerRegionId: yup.number().notRequired().transform((value: any) => value || undefined),
  status: yup.array().required().min(1, "At least one status is required"),
  createdById: yup.number().notRequired(),
  dateType: yup.mixed<OpportunitySearchCriteriaDateTypeEnum>().required(),
  startDate: yup.date()
    .typeError("Invalid date")
    .required("From date is required"),
  endDate: yup.date()
    .typeError("Invalid date")
    .required("To date is required")
    .mustBeAfter("startDate", "To date must be after From date")
    .when('startDate', (startDate: Date, schema: any) => {
      return schema.max(moment(startDate).add(1, "years").startOf("day").toDate(), "Date range cannot be longer than 1 year");
    })
});

export const SalesHistoryReportParametersValidationSchema: SchemaOf<NullableOptional<SalesHistoryReportParameters>> = yup.object({
  startDate: yup.date()
    .typeError("Invalid date")
    .required("From date is required"),
  endDate: yup.date()
    .typeError("Invalid date")
    .required("To date is required")
    .mustBeAfter("startDate", "To date must be after From date")
    .when('startDate', (startDate: Date, schema: any) => {
      return schema.max(moment(startDate).add(3, "years").startOf("day").toDate(), "Date range cannot be longer than 3 years");
    }),
  customerRegionId: yup.number().notRequired().transform((value: any) => value || undefined),
  accountManagerId: yup.number().notRequired().allowNaN()
});

interface IReportServiceState {
  bookedSalesReportFilter: IBookedSalesReportFilter;
  bookedSalesReportFilterErrors: ValidationError | null;
  bookedSalesReportFetchResults: IAjaxState<BookedSalesReport>;
  bookedSalesFiltersFetchResults: IAjaxState<BookedSalesFilters>;

  quoteConversionParameters: ConversionSearchCriteria;
  quoteConversionParametersValidationErrors: ValidationError | null;
  quoteConversionReportFetchResults: IAjaxState<QuoteConversionReportRow[]>;

  quoteReviewsParameters: ReviewSearchCriteria;
  quoteReviewsParametersValidationErrors: ValidationError | null;
  quoteReviewsReportFetchResults: IAjaxState<QuoteReviewsReportRow[]>;

  deliveredFreightSearchCriteria: DeliveredFreightSearchCriteria;
  deliveredFreightSearchCriteriaValidationErrors: ValidationError | null;
  deliveredFreightReportFetchResults: IAjaxState<DeliveredFreightReport>;
  deliveredFreightParametersValidationErrors: ValidationError | null;
  deliveredFreightSalesRepData: DeliveredFreightReportView[];
  deliveredFreightCompanyData: DeliveredFreightReportView[];
  
  laneRevenueFetchResults: IAjaxState<LaneRevenueReportView[]>;
  laneRevenueParameters: LaneRevenueParametersVM;
  tripDetailsFetchResults: IAjaxState<TripDetailsLegView[]>;

  opportunityParameters: OpportunitySearchCriteria;
  opportunityParametersValidationErrors: ValidationError | null;
  opportunityReportFetchResults: IAjaxState<Opportunity[]>;
  opportunityFilters: ISalesOpportunitiesFilters;

  salesHistoryParameters: SalesHistoryReportParameters;
  salesHistoryParametersValidationErrors: ValidationError | null;
  salesHistoryReportFetchResults: IAjaxState<Customer[]>;
}

const InjectedPropName = "reportService";

const initialState = {
  bookedSalesReportFetchResults: managedAjaxUtil.createInitialState(),
  bookedSalesFiltersFetchResults: managedAjaxUtil.createInitialState(),
  bookedSalesReportFilter: {
    startDate: new Date(),
    endDate: new Date()
  },
  bookedSalesReportFilterErrors: null,
  quoteConversionParameters: {},
  quoteConversionParametersValidationErrors: null,
  quoteConversionReportFetchResults: managedAjaxUtil.createInitialState(),
  quoteReviewsParameters: {
    dateType: "QuoteDate"
  },
  quoteReviewsParametersValidationErrors: null,
  quoteReviewsReportFetchResults: managedAjaxUtil.createInitialState(),
  deliveredFreightSearchCriteria: {
    sortColumn: "quoteDate",
    sortAscending: false,
    startIndex: 0,
    pageSize: 1000,
  },
  deliveredFreightSearchCriteriaValidationErrors: null,
  deliveredFreightReportFetchResults: managedAjaxUtil.createInitialState(),
  laneRevenueFetchResults: managedAjaxUtil.createInitialState(),
  laneRevenueParameters: {},
  tripDetailsFetchResults: managedAjaxUtil.createInitialState(),
  opportunityParameters: {
    dateType: "CloseDate",
    status: ["Discovery", "Interested", "Converted"]
  },
  opportunityParametersValidationErrors: null,
  opportunityReportFetchResults: managedAjaxUtil.createInitialState(),
  opportunityFilters: {
    companyKey: ""
  },
  salesHistoryParameters: {},
  salesHistoryParametersValidationErrors: null,
  salesHistoryReportFetchResults: managedAjaxUtil.createInitialState()
} as IReportServiceState;

class ReportFreezerService extends FreezerService<IReportServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public clearBookedSalesReport() {
    this.freezer.get().set({
      bookedSalesReportFetchResults: managedAjaxUtil.createInitialState(),
      bookedSalesFiltersFetchResults: managedAjaxUtil.createInitialState(),
      bookedSalesReportFilter: {
        startDate: undefined,
        endDate: undefined
      },
      bookedSalesReportFilterErrors: null,
    });
  }

  public fetchBookedSalesFilters() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "bookedSalesFiltersFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getBookedSalesFilters(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to load booked sales report filters");
      }
    });
  }

  public async fetchBookedSalesReport() {
    const bookedSalesReportFilter = this.freezer.get().bookedSalesReportFilter.toJS();

    const filterErrors = await validateSchema(BookedSalesReportFilterValidationSchema, bookedSalesReportFilter);
    this.freezer.get().set({ bookedSalesReportFilterErrors: filterErrors });

    if (filterErrors) {
      return;
    }

    const startDate = moment(bookedSalesReportFilter.startDate).startOf("day");
    const endDate = moment(bookedSalesReportFilter.endDate).endOf("day");

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "bookedSalesReportFetchResults",
      params: {
        body: {
          startDate: startDate?.toDate(),
          endDate: endDate?.toDate()
        }
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getBookedSalesReport(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to get booked sales report");
      },
      onOk: (fetchData: BookedSalesReport) => {
        const {
          bookedSalesFiltersFetchResults
        } = this.getState();

        let filterData = bookedSalesFiltersFetchResults.data;
        const reportData = fetchData.reportData;

        if (filterData && reportData) {
          let newCi = _.map(filterData?.companyInfos, (ci, idx) => {
            let returnMe: TmwinCompanyInfo = {
              ...ci
            };

            returnMe.isEnabled = _.some(reportData, r => r.companyName === ci.name);
            returnMe.isChecked = returnMe.isEnabled;

            return returnMe;
          });

          let newT = _.map(filterData?.terminals, (t, idx) => {
            let returnMe: TmwinTerminal = {
              ...t
            };

            returnMe.isEnabled = _.some(reportData, r => r.terminalName === t.desc);
            returnMe.isChecked = returnMe.isEnabled;

            return returnMe;
          });

          let newS = _.map(filterData?.salesAgents, (s, idx) => {
            let returnMe: TlorderSalesAgent = {
              ...s
            };

            returnMe.isEnabled = _.some(reportData, r => r.username === s.salesAgent);
            returnMe.isChecked = returnMe.isEnabled;

            return returnMe;
          })

          this.freezer.get().bookedSalesFiltersFetchResults.data?.set({
            companyInfos: newCi,
            terminals: newT,
            salesAgents: newS
          });
        }
      }
    });
  }

  public async fetchLaneRevenueReport(criteria: LaneRevenueParametersVM) {
    // save parameters that are actually used to run the report
    this.freezer.get().laneRevenueParameters.set(criteria);
    
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "laneRevenueFetchResults",
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getLaneRevenueReport(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to get lane revenue analysis report");
      }
    });
  }

  public fetchTripDetails(tripNumber: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tripDetailsFetchResults",
      params: {
        tripNumber: tripNumber
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getTripDetails(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to get trip details");
      }
    });
  }

  public onCompanyFilterChange(companyId: number, isChecked: boolean) {
    const { bookedSalesFiltersFetchResults } = this.getState();
    let ciFilter = _.findIndex(bookedSalesFiltersFetchResults.data?.companyInfos, (f) => f.id === companyId);

    if (ciFilter >= 0) {
      this.freezer.get().bookedSalesFiltersFetchResults?.data?.companyInfos?.[ciFilter].set({ isChecked });
    }
  }

  public onTerminalFilterChange(terminalId: number, isChecked: boolean) {
    const { bookedSalesFiltersFetchResults } = this.getState();
    let tFilter = _.findIndex(bookedSalesFiltersFetchResults.data?.terminals, (f) => f.id === terminalId);

    if (tFilter >= 0) {
      this.freezer.get().bookedSalesFiltersFetchResults?.data?.terminals?.[tFilter].set({ isChecked });
    }
  }

  public onSalesRepFilterChange(salesRep: string, isChecked: boolean) {
    const { bookedSalesFiltersFetchResults } = this.getState();
    let sFilter = _.findIndex(bookedSalesFiltersFetchResults.data?.salesAgents, (f) => f.salesAgent === salesRep);

    if (sFilter >= 0) {
      this.freezer.get().bookedSalesFiltersFetchResults?.data?.salesAgents?.[sFilter].set({ isChecked });
    }
  }

  public updateCheckboxFilterSection(filterType: string, shouldReset: boolean) {
    const {
      bookedSalesFiltersFetchResults
    } = this.getState();

    const filterData = bookedSalesFiltersFetchResults.data;
    if (!filterData) return;

    let updatedSection = undefined;
    if (filterType === "companyInfos") {
      updatedSection = _.map(filterData?.companyInfos, (ci, idx) => {
        return {
          ...ci,
          isChecked: shouldReset ? ci.isEnabled : false
        };
      });
    }

    if (filterType === "terminals") {
      updatedSection = _.map(filterData?.terminals, (t, idx) => {
        return {
          ...t,
          isChecked: shouldReset ? t.isEnabled : false
        };
      });
    }

    if (filterType === "salesAgents") {
      updatedSection = _.map(filterData?.salesAgents, (s, idx) => {
        return {
          ...s,
          isChecked: shouldReset ? s.isEnabled : false
        };
      });
    }

    this.freezer.get().bookedSalesFiltersFetchResults.data?.set({
      [filterType]: updatedSection
    });
  }

  public updateBookedSalesReportFilter(filterSettings: Partial<IBookedSalesReportFilter>) {
    this.freezer.get().bookedSalesReportFilter.set(filterSettings);
  }

  public updateSalesOpportunitiesCompanyFilter(companyKey: string) {
    this.freezer.get().opportunityFilters.set({ companyKey: companyKey });
  }

  public updateSalesOpportunitiesCheckboxFilter(filterType: keyof ISalesOpportunitiesFilters, id: number | undefined, checked: boolean) {
    const { opportunityFilters } = this.getState();

    // keyof is always ICheckboxFilter[] here; companyKey string is handled in the other method
    let foo = _.findIndex(opportunityFilters[filterType] as ICheckboxFilter[], (f) => f.id === id);
    if (foo >= 0) {
      (this.freezer.get().opportunityFilters[filterType][foo] as Freezer.Types.IFrozenObject<ICheckboxFilter>).set({ isChecked: checked });
    }
  }

  public fetchQuoteConversionReport(criteria: ConversionSearchCriteria) {
    // save parameters that are actually used to run the report
    this.freezer.get().quoteConversionParameters.set(criteria);

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteConversionReportFetchResults",
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getQuoteConversionReport(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quote conversion report.");
      }
    });
  }

  public fetchQuoteReviewsReport(criteria: ReviewSearchCriteria) {
    // save parameters that are actually used to run the report
    this.freezer.get().quoteReviewsParameters.set(criteria);

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteReviewsReportFetchResults",
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getQuoteReviewsReport(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch quote reviews report.");
      }
    });
  }

  public updateSearchCriteria(searchCriteria: Partial<DeliveredFreightSearchCriteria>) {
    this.freezer.get().deliveredFreightSearchCriteria?.set(searchCriteria);
  }

  public searchPage(newPage: number) {
    const criteria = this.freezer.get().deliveredFreightSearchCriteria?.toJS();

    this.updateSearchCriteria({ startIndex: (newPage - 1) * (criteria?.pageSize ?? 0) });
    this.fetchDeliveredFreightReport();
  }

  public fetchDeliveredFreightReport(searchCriteria?: DeliveredFreightSearchCriteria) {
    const { deliveredFreightSearchCriteria } = this.freezer.get();
    const startIndex = 0;

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "deliveredFreightReportFetchResults",
      params: {
        body: searchCriteria ? 
          deliveredFreightSearchCriteria.set({...searchCriteria, startIndex}).toJS()
        : deliveredFreightSearchCriteria.toJS()
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getDeliveredFreightReport(params);
      },
      onOk: (data: DeliveredFreightReport) => {
        this.freezer.get().set({
          deliveredFreightSalesRepData: data.bySalesRepData,
          deliveredFreightCompanyData: data.byCompanyData
        });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch delivered freight report.");
      }
    });
  }

  public fetchSalesOpportunitiesReport(criteria: OpportunitySearchCriteria) {
    // save parameters that are actually used to run the report
    this.freezer.get().opportunityParameters.set(criteria);

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "opportunityReportFetchResults",
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getSalesOpportunitiesReport(params);
      },
      onOk: (data: Opportunity[]) => {
        const customers = _.map(_.uniqBy(data, (x) => x.customer?.id), (y) => {
          return {
            id: y.customer?.id,
            displayName: y.customer?.customerName,
            isChecked: true
          } as ICheckboxFilter
        });

        const salesReps = _.map(_.uniqBy(data, (x) => x.createdBy?.linkId), (y) => {
          return {
            id: y.createdBy?.linkId,
            displayName: y.createdBy?.linkName,
            isChecked: true
          } as ICheckboxFilter
        });

        const leadSources = _.map(_.uniqBy(data, (x) => x.leadSource?.linkId), (y) => {
          return {
            id: y.leadSource?.linkId,
            displayName: y.leadSource?.linkName,
            isChecked: true
          } as ICheckboxFilter
        });

        this.freezer.get().opportunityFilters.set({
          companyKey: "",
          customers: customers,
          salesReps: salesReps,
          leadSources: leadSources
        });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch sales opportunities report.");
      }
    });
  }

  public fetchSalesHistoryReport(criteria: SalesHistoryReportParameters) {
    // save parameters that are actually used to run the report
    this.freezer.get().salesHistoryParameters.set(criteria);

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "salesHistoryReportFetchResults",
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getSalesHistoryReport(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch sales history report.");
      }
    });
  }
}

export const ReportService = new ReportFreezerService();
export type IReportServiceInjectedProps = ReturnType<ReportFreezerService["getPropsForInjection"]>;
