/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface AboutVM {
    "productName"?: string;
    "productVersion"?: string;
    "salesPortalUrl"?: string;
    "reportingUrl"?: string;
}

export interface AccessorialCharge {
    "id"?: number;
    "tmChargeCode"?: string;
    "description"?: string;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "accessorialChargeValues"?: Array<AccessorialChargeValue>;
}

export interface AccessorialChargeValue {
    "id"?: number;
    "accessorialChargeId"?: number;
    "amount"?: number;
    "createdById"?: number;
    "createdOn"?: Date;
    "notes"?: string;
    "quoteId"?: number;
    "status"?: AccessorialChargeValueStatusEnum;
    "accessorialCharge"?: AccessorialCharge;
    "quote"?: Quote;
}

export type AccessorialChargeValueStatusEnum = "Pending" | "Approved";
export interface Activity {
    "id"?: number;
    "customerId"?: number;
    "noteText"?: string;
    "isPinned"?: boolean;
    "createdById"?: number;
    "createdOn"?: Date;
    "isActive"?: boolean;
    "activityType"?: ActivityActivityTypeEnum;
    "createdBy"?: Employee;
    "customer"?: Customer;
}

export type ActivityActivityTypeEnum = "Note" | "PhoneCallCold" | "PhoneCallFirstAppt" | "PhoneCallFollowup" | "InPersonColdCall" | "InPersonFirstAppt" | "InPersonFollowup" | "EmailColdCall" | "EmailFollowup" | "MarketingCampaign" | "Proposal" | "NewAccountHandoff" | "CustomerToProspectHandoff" | "Other";
export interface Address {
    "id"?: number;
    "quoteStopId"?: number;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "customerId"?: number;
    "addressType"?: AddressAddressTypeEnum;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "isCurrent"?: boolean;
    "customer"?: Customer;
    "region"?: Region;
}

export type AddressAddressTypeEnum = "Shipper" | "Consignee";
export interface ApplicationSetting {
    "id"?: number;
    "companyId"?: number;
    "settingsKey"?: string;
    "settingsValue"?: string;
    "createdOn"?: Date;
    "createdById"?: number;
    "modifiedOn"?: Date;
    "modifiedById"?: number;
    "company"?: Company;
    "createdBy"?: Employee;
    "modifiedBy"?: Employee;
}

export interface BookedSalesFilters {
    "companyInfos"?: Array<TmwinCompanyInfo>;
    "terminals"?: Array<TmwinTerminal>;
    "salesAgents"?: Array<TlorderSalesAgent>;
}

export interface BookedSalesParametersVM {
    "companyId"?: number;
    "startDate"?: Date;
    "endDate"?: Date;
    "metricType"?: string;
}

export interface BookedSalesReport {
    "fromDate"?: Date;
    "toDate"?: Date;
    "totalSales"?: number;
    "totalWeight"?: number;
    "totalFeet"?: number;
    "totalRateFtMile"?: number;
    "reportData"?: Array<BookedSalesReportView>;
}

export interface BookedSalesReportView {
    "username"?: string;
    "companyName"?: string;
    "terminalName"?: string;
    "bills"?: number;
    "totalDistance"?: number;
    "totalWeight"?: number;
    "charges"?: number;
    "totalRollupWeight"?: number;
    "totalRollupLength"?: number;
    "rk"?: number;
    "rkm"?: number;
    "rf"?: number;
    "rfm"?: number;
}

export interface CapacityReport {
    "terminals"?: Array<CapacityReportTerminal>;
    "jvlCostPerMile"?: number;
    "sfsCostPerMile"?: number;
    "emptyLegs"?: Array<CapacityReportEmptyLeg>;
}

export interface CapacityReportEmptyLeg {
    "tripNumber"?: number;
    "tripDate"?: Date;
    "legSequenceNumber"?: number;
    "legOriginZoneDescription"?: string;
    "legDestinationZoneDescription"?: string;
    "legMiles"?: number;
    "driverName"?: string;
}

export interface CapacityReportParameters {
    "startDate"?: Date;
    "endDate"?: Date;
    "showZonesWithNoTrips"?: boolean;
}

export interface CapacityReportRow {
    "zone"?: string;
    "tripCount"?: number;
    "miles"?: number;
    "rpm"?: number;
    "rpk"?: number;
    "averageOpenWeight"?: number;
    "openCapacity"?: number;
    "averageBolCount"?: number;
}

export interface CapacityReportTerminal {
    "terminal"?: string;
    "direction"?: CapacityReportTerminalDirectionEnum;
    "rows"?: Array<CapacityReportRow>;
}

export type CapacityReportTerminalDirectionEnum = "Unknown" | "Inbound" | "Outbound";
export interface Commodity {
    "id"?: number;
    "commodityName"?: string;
    "commodityDescription"?: string;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodityShortName"?: string;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "tmCommodityId"?: string;
    "commodityValues"?: Array<CommodityValue>;
    "rateModelTests"?: Array<RateModelTest>;
    "commodityQuestions"?: Array<CommodityQuestion>;
    "commodityExclusions"?: Array<CommodityExclusion>;
}

export interface CommodityExclusion {
    "id"?: number;
    "commodityId"?: number;
    "customerId"?: number;
    "commodity"?: Commodity;
    "customer"?: Customer;
}

export interface CommodityQuestion {
    "id"?: number;
    "questionId"?: number;
    "commodityId"?: number;
    "commodity"?: Commodity;
    "question"?: Question;
}

export interface CommodityValue {
    "id"?: number;
    "commodityId"?: number;
    "zoneId"?: number;
    "companyId"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "percentRate"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodity"?: Commodity;
    "company"?: Company;
    "zone"?: Zone;
}

export interface Company {
    "id"?: number;
    "companyKey"?: string;
    "companyName"?: string;
    "primaryRate"?: number;
    "secondaryRate"?: number;
    "tmcompanyId"?: number;
    "adminEmployeeId"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
}

export interface ContactType {
    "id"?: number;
    "type"?: string;
    "customerContacts"?: Array<CustomerContact>;
}

export interface ConversionSearchCriteria {
    "startDate"?: Date;
    "endDate"?: Date;
    "companyId"?: number;
}

export interface Customer {
    "id"?: number;
    "accountManagerId"?: number;
    "businessDevManagerId"?: number;
    "customerSourceId"?: number;
    "prospectId"?: number;
    "customerName"?: string;
    "tmcustomerId"?: string;
    "contactName"?: string;
    "emailAddress"?: string;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "regionId"?: number;
    "phoneNumber"?: string;
    "cellNumber"?: string;
    "zipPostalCode"?: string;
    "website"?: string;
    "isCaller"?: boolean;
    "isShipper"?: boolean;
    "isConsignee"?: boolean;
    "isActive"?: boolean;
    "isProspect"?: boolean;
    "sourceDetails"?: string;
    "displayAlert"?: boolean;
    "alert"?: string;
    "customerSince"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "hasCustomerPortalAccess"?: boolean;
    "isBillTo"?: boolean;
    "billingStatus"?: CustomerBillingStatusEnum;
    "creditLimit"?: number;
    "dueDays"?: number;
    "hasBillingAddress"?: boolean;
    "billingAddress1"?: string;
    "billingAddress2"?: string;
    "billingCity"?: string;
    "billingRegionId"?: number;
    "billingZipPostalCode"?: string;
    "oneTimeTmSync"?: boolean;
    "estAvgMonthlyFreightBills"?: number;
    "estAvgRevenuePerFreightBill"?: number;
    "accountManager"?: Employee;
    "businessDevManager"?: Employee;
    "customerSource"?: CustomerSource;
    "prospect"?: Prospect;
    "billingRegion"?: Region;
    "region"?: Region;
    "quotes"?: Array<Quote>;
    "commodityExclusions"?: Array<CommodityExclusion>;
    "customerQuotes"?: Array<CustomerQuote>;
    "customerContacts"?: Array<CustomerContact>;
    "activities"?: Array<Activity>;
    "customerHours"?: Array<CustomerHour>;
    "customerLoadingInstructions"?: Array<CustomerLoadingInstruction>;
}

export type CustomerBillingStatusEnum = "CheckCredit" | "CreditConfirmed" | "CODOnly";
export interface CustomerContact {
    "id"?: number;
    "customerId"?: number;
    "customerUserId"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "nameSuffixId"?: number;
    "emailAddress"?: string;
    "phoneNumber"?: string;
    "cellNumber"?: string;
    "title"?: string;
    "notes"?: string;
    "contactTypeId"?: number;
    "isPrimary"?: boolean;
    "isActive"?: boolean;
    "isHidden"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "contactType"?: ContactType;
    "customer"?: Customer;
    "customerUser"?: CustomerUser;
    "nameSuffix"?: NameSuffix;
}

export interface CustomerEntityLink {
    "isProspect"?: boolean;
    "linkId"?: number;
    "linkName"?: string;
}

export interface CustomerHour {
    "id"?: number;
    "allDay"?: boolean;
    "closeTime"?: string;
    "closed"?: boolean;
    "customerId"?: number;
    "dayOfWeek"?: CustomerHourDayOfWeekEnum;
    "openTime"?: string;
    "auditDisplayValue"?: string;
    "hasHours"?: boolean;
}

export type CustomerHourDayOfWeekEnum = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
export interface CustomerLoadingInstruction {
    "id"?: number;
    "customerId"?: number;
    "loadingInstructionId"?: number;
    "customer"?: Customer;
    "loadingInstruction"?: LoadingInstruction;
}

export interface CustomerQuote {
    "id"?: number;
    "companyId"?: number;
    "customerId"?: number;
    "quoteDate"?: Date;
    "expirationDate"?: Date;
    "shipperZipPostalCode"?: string;
    "shipperStartDate"?: Date;
    "shipperEndDate"?: Date;
    "shipperHardTime"?: string;
    "isShipperAppointmentRequired"?: boolean;
    "consigneeZipPostalCode"?: string;
    "consigneeStartDate"?: Date;
    "consigneeEndDate"?: Date;
    "consigneeHardTime"?: string;
    "isConsigneeAppointmentRequired"?: boolean;
    "declaredValue"?: number;
    "description"?: string;
    "notes"?: string;
    "tarpId"?: number;
    "equipmentTypeId"?: number;
    "rateEngineResults"?: string;
    "quoteStatus"?: CustomerQuoteQuoteStatusEnum;
    "quoteStatusMessage"?: string;
    "mileage"?: number;
    "datRate"?: number;
    "rateVariable"?: number;
    "rateVariableFactor"?: CustomerQuoteRateVariableFactorEnum;
    "rate"?: number;
    "createdOn"?: Date;
    "createdById"?: number;
    "isExpedited"?: boolean;
    "quoteNumber"?: number;
    "copiedQuoteNumber"?: number;
    "copiedQuoteRate"?: number;
    "poNumber"?: string;
    "customerContactId"?: number;
    "isReviewed"?: boolean;
    "isMilitaryBase"?: boolean;
    "isTwicCardRequired"?: boolean;
    "fullQuote"?: SimplifiedFullQuote;
    "company"?: Company;
    "createdBy"?: CustomerUser;
    "customer"?: Customer;
    "customerContact"?: CustomerContact;
    "tarp"?: Tarp;
    "equipmentType"?: EquipmentType;
    "customerQuoteFreights"?: Array<CustomerQuoteFreight>;
}

export type CustomerQuoteQuoteStatusEnum = "Pending" | "Accepted" | "Declined" | "ApprovalNeeded" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type CustomerQuoteRateVariableFactorEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface CustomerQuoteFreight {
    "id"?: number;
    "customerQuoteId"?: number;
    "commodityId"?: number;
    "numberOfPieces"?: number;
    "weight"?: number;
    "width"?: number;
    "length"?: number;
    "height"?: number;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "createdOn"?: Date;
    "commodity"?: Commodity;
    "customerQuote"?: CustomerQuote;
}

export interface CustomerSource {
    "id"?: number;
    "name"?: string;
    "isActive"?: boolean;
    "requiresAdditionalDetail"?: boolean;
}

export interface CustomerUser {
    "id"?: number;
    "userId"?: string;
    "type"?: CustomerUserTypeEnum;
    "firstName"?: string;
    "lastName"?: string;
    "emailAddress"?: string;
    "tmcustomerId"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "customerQuotes"?: Array<CustomerQuote>;
    "customerContact"?: CustomerContact;
}

export type CustomerUserTypeEnum = "SalesRep" | "Manager";
export interface DatRate {
    "originZipPostalCode"?: string;
    "originMarket"?: string;
    "destZipPostalCode"?: string;
    "destMarket"?: string;
    "rate"?: number;
    "miles"?: number;
    "timeFrameDays"?: number;
    "modifiedOn"?: Date;
    "isCachedZoneRate"?: boolean;
    "hasErrors"?: boolean;
    "message"?: string;
}

export interface DeliveredFreightByCompany {
    "company"?: string;
    "revenue"?: number;
}

export interface DeliveredFreightBySalesRep {
    "company"?: string;
    "salesRep"?: string;
    "revenue"?: number;
}

export interface DeliveredFreightReport {
    "totalRecords"?: number;
    "numberOfRecords"?: number;
    "reportData"?: Array<DeliveredFreightReportView>;
    "byCompanyData"?: Array<DeliveredFreightByCompany>;
    "bySalesRepData"?: Array<DeliveredFreightBySalesRep>;
}

export interface DeliveredFreightReportView {
    "freightBillNumber"?: string;
    "deliveryDate"?: Date;
    "revenue"?: number;
    "salesRep"?: string;
    "company"?: string;
}

export interface DeliveredFreightSearchCriteria {
    "companyId"?: number;
    "startDate"?: Date;
    "endDate"?: Date;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export interface Employee {
    "id"?: number;
    "userId"?: string;
    "firstName"?: string;
    "lastName"?: string;
    "emailAddress"?: string;
    "isAdmin"?: boolean;
    "isManager"?: boolean;
    "isSalesRep"?: boolean;
    "isBiller"?: boolean;
    "isViewOnly"?: boolean;
    "isCarrierManager"?: boolean;
    "isActive"?: boolean;
    "tmuserCode"?: string;
    "tmsalesAgent"?: string;
    "phoneNumber"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
}

export interface EmployeeSearchCriteria {
    "isSalesRep"?: boolean;
    "isCarrierRep"?: boolean;
    "isManager"?: boolean;
    "isAccOrBusDevManager"?: boolean;
    "includeInactive"?: boolean;
}

export interface EntityLink {
    "linkId"?: number;
    "linkName"?: string;
}

export interface EquipmentType {
    "id"?: number;
    "name"?: string;
    "tmEquipmentCode"?: string;
    "overdimensionalRulesetId"?: number;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "equipmentTypeValues"?: Array<EquipmentTypeValue>;
    "quotes"?: Array<Quote>;
    "customerQuotes"?: Array<CustomerQuote>;
}

export interface EquipmentTypeValue {
    "id"?: number;
    "equipmentTypeId"?: number;
    "companyId"?: number;
    "percentRate"?: number;
    "createdById"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "equipmentType"?: EquipmentType;
    "createdBy"?: Employee;
}

export interface LaneRevenueParametersVM {
    "startDate"?: Date;
    "endDate"?: Date;
    "originProvince"?: string;
    "destinationProvince"?: string;
    "companyId"?: number;
}

export interface LaneRevenueReportView {
    "tripNumber"?: number;
    "completeDate"?: Date;
    "originCity"?: string;
    "originPostalCode"?: string;
    "destinationCity"?: string;
    "destinationPostalCode"?: string;
    "revenue"?: number;
    "distance"?: number;
    "length"?: number;
    "weight"?: number;
}

export interface LoadingInstruction {
    "id"?: number;
    "instruction"?: string;
    "appliesTo"?: LoadingInstructionAppliesToEnum;
    "isActive"?: boolean;
}

export type LoadingInstructionAppliesToEnum = "ShippersOnly" | "ConsigneesOnly" | "BothShippersAndConsignees";
export interface NameSuffix {
    "id"?: number;
    "suffixValue"?: string;
    "customerContacts"?: Array<CustomerContact>;
}

export interface Opportunity {
    "id"?: number;
    "opportunityId"?: number;
    "customer"?: Customer;
    "company"?: Company;
    "leadSource"?: EntityLink;
    "leadDetails"?: string;
    "status"?: OpportunityStatusEnum;
    "confidence"?: number;
    "targetRevenue"?: number;
    "description"?: string;
    "closeDate"?: Date;
    "createdBy"?: EntityLink;
    "createdOn"?: Date;
}

export type OpportunityStatusEnum = "Discovery" | "Interested" | "Converted" | "Abandoned";
export interface OpportunitySearchCriteria {
    "customerId"?: number;
    "customerRegionId"?: number;
    "status"?: Array<OpportunitySearchCriteriaStatusEnum>;
    "dateType"?: OpportunitySearchCriteriaDateTypeEnum;
    "startDate"?: Date;
    "endDate"?: Date;
    "createdById"?: number;
}

export type OpportunitySearchCriteriaStatusEnum = "Discovery" | "Interested" | "Converted" | "Abandoned";
export type OpportunitySearchCriteriaDateTypeEnum = "CloseDate" | "CreationDate";
export interface Prospect {
    "id"?: number;
    "currentProvider"?: string;
    "decisionMakingType"?: ProspectDecisionMakingTypeEnum;
    "industryType"?: ProspectIndustryTypeEnum;
    "percentageToClose"?: ProspectPercentageToCloseEnum;
    "pricingType"?: ProspectPricingTypeEnum;
    "startDate"?: Date;
    "customer"?: Customer;
}

export type ProspectDecisionMakingTypeEnum = "Customer" | "DealerNetwork" | "Local" | "Other";
export type ProspectIndustryTypeEnum = "Aerospace" | "Equipment" | "GeneratorsTransformers" | "MachineTools" | "Steel" | "Other" | "Broker";
export type ProspectPercentageToCloseEnum = "NoContactWithDM" | "ContactedDMInterestExpressed" | "Quoted" | "VerballyAcceptedQuote" | "OrderPlaced";
export type ProspectPricingTypeEnum = "Contracted" | "SpotQuote";
export interface Question {
    "id"?: number;
    "questionText"?: string;
    "questionType"?: QuestionQuestionTypeEnum;
    "isNa"?: boolean;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodityQuestions"?: Array<CommodityQuestion>;
}

export type QuestionQuestionTypeEnum = "Commodity" | "Upcharge";
export interface Quote {
    "id"?: number;
    "quoteNumber"?: number;
    "companyId"?: number;
    "customerId"?: number;
    "billToId"?: number;
    "createdById"?: number;
    "quoteDate"?: Date;
    "deliveryDate"?: Date;
    "negotiatedRate"?: number;
    "isMarketPrimary"?: boolean;
    "notes"?: string;
    "status"?: QuoteStatusEnum;
    "reviewedById"?: number;
    "finalizedById"?: number;
    "expirationDate"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "upchargePercentage"?: number;
    "flatUpcharge"?: number;
    "otherFlatUpchargeReason"?: boolean;
    "flatUpchargeReason"?: string;
    "miles"?: number;
    "percentUpchargeReason"?: string;
    "totalFreightLength"?: number;
    "equipmentTypeId"?: number;
    "workflowState"?: string;
    "declineReasonText"?: string;
    "carrierId"?: number;
    "customerContactId"?: number;
    "quoteType"?: QuoteQuoteTypeEnum;
    "contactName"?: string;
    "contactPhoneNumber"?: string;
    "cancellationReason"?: QuoteCancellationReasonEnum;
    "cancellationDetails"?: string;
    "customerQuote"?: EntityLink;
    "billingStatus"?: QuoteBillingStatusEnum;
    "paymentCollected"?: boolean;
    "billTo"?: Customer;
    "company"?: Company;
    "customer"?: Customer;
    "createdBy"?: Employee;
    "reviewedBy"?: Employee;
    "finalizedBy"?: Employee;
    "equipmentType"?: EquipmentType;
    "carrier"?: QuoteCarrier;
    "customerContact"?: CustomerContact;
    "quoteStops"?: Array<QuoteStop>;
    "quoteFreights"?: Array<QuoteFreight>;
    "quoteQuestions"?: Array<QuoteQuestion>;
    "quoteStatusHistories"?: Array<QuoteStatusHistory>;
    "calculatedRates"?: Array<QuoteCalculatedRate>;
    "approvalReasons"?: Array<QuoteApprovalReason>;
    "accessorialChargeValues"?: Array<AccessorialChargeValue>;
}

export type QuoteStatusEnum = "Pending" | "Accepted" | "Declined" | "ApprovalNeeded" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type QuoteQuoteTypeEnum = "Full" | "Quick" | "Contract";
export type QuoteCancellationReasonEnum = "Customer" | "Kaiser" | "Other";
export type QuoteBillingStatusEnum = "CheckCredit" | "CreditConfirmed" | "CODOnly";
export interface QuoteApprovalReason {
    "id"?: number;
    "quoteId"?: number;
    "approvalNeededReasonId"?: QuoteApprovalReasonApprovalNeededReasonIdEnum;
    "approvalStatus"?: QuoteApprovalReasonApprovalStatusEnum;
    "reviewedOn"?: Date;
    "createdById"?: number;
    "createdOn"?: Date;
    "reviewedById"?: number;
    "reviewedBy"?: Employee;
}

export type QuoteApprovalReasonApprovalNeededReasonIdEnum = "OverDimensional" | "LowNegotiatedRate" | "DeclaredValue" | "MileageDifferenceOverThreshold" | "NegotiatedRateOutOfRange" | "ShipperZoneChanged" | "ConsigneeZoneChanged";
export type QuoteApprovalReasonApprovalStatusEnum = "PendingApproval" | "Approved" | "Denied";
export interface QuoteCalculatedRate {
    "id"?: number;
    "quoteId"?: number;
    "isCurrent"?: boolean;
    "miles"?: number;
    "totalFreightLength"?: number;
    "totalFreightWeight"?: number;
    "rateLevelFactor"?: QuoteCalculatedRateRateLevelFactorEnum;
    "calculatedRateVariable"?: number;
    "overriddenRateVariable"?: number;
    "rateEngineResults"?: string;
    "highRate"?: number;
    "lowRate"?: number;
    "datRate"?: number;
    "datRateMessage"?: string;
    "timeFrameDays"?: number;
    "originMarket"?: string;
    "destMarket"?: string;
    "createdOn"?: Date;
}

export type QuoteCalculatedRateRateLevelFactorEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface QuoteCarrier {
    "id"?: number;
    "carrierStatus"?: QuoteCarrierCarrierStatusEnum;
    "vendorId"?: number;
    "carrierRate"?: number;
    "tripNumber"?: number;
    "carrierRepId"?: number;
    "carrierRep"?: Employee;
    "vendor"?: Vendor;
    "quote"?: Quote;
}

export type QuoteCarrierCarrierStatusEnum = "Available" | "Assigned" | "OnHold" | "Picked" | "Delivered";
export interface QuoteConversionReportRow {
    "salesRep"?: string;
    "acceptedQuotes"?: number;
    "totalQuotes"?: number;
    "conversionRate"?: number;
    "bookedAmount"?: number;
    "avgAmountPerQuote"?: number;
}

export interface QuoteFreight {
    "id"?: number;
    "quoteStopId"?: number;
    "commodityId"?: number;
    "weight"?: number;
    "width"?: number;
    "length"?: number;
    "height"?: number;
    "description"?: string;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "isGrouped"?: boolean;
    "rotated"?: boolean;
    "numberOfPieces"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "quoteId"?: number;
    "serialRefNumber"?: string;
    "commodity"?: Commodity;
    "quoteStop"?: QuoteStop;
    "quote"?: Quote;
    "quoteStopFreightQuestions"?: Array<QuoteStopFreightQuestion>;
}

export interface QuoteQuestion {
    "id"?: number;
    "quoteId"?: number;
    "questionId"?: number;
    "answer"?: QuoteQuestionAnswerEnum;
    "question"?: Question;
    "quote"?: Quote;
}

export type QuoteQuestionAnswerEnum = "Yes" | "No" | "NA";
export interface QuoteReviewsReportRow {
    "quoteId"?: number;
    "quoteNumber"?: string;
    "quoteDate"?: Date;
    "caller"?: CustomerEntityLink;
    "salesRep"?: string;
    "company"?: string;
    "reviewDecision"?: QuoteReviewsReportRowReviewDecisionEnum;
    "reviewer"?: string;
    "approvalNeededTrigger"?: QuoteReviewsReportRowApprovalNeededTriggerEnum;
    "denialReason"?: string;
    "reviewDate"?: Date;
}

export type QuoteReviewsReportRowReviewDecisionEnum = "PendingApproval" | "Approved" | "Denied";
export type QuoteReviewsReportRowApprovalNeededTriggerEnum = "OverDimensional" | "LowNegotiatedRate" | "DeclaredValue" | "MileageDifferenceOverThreshold" | "NegotiatedRateOutOfRange" | "ShipperZoneChanged" | "ConsigneeZoneChanged";
export interface QuoteStatusHistory {
    "id"?: number;
    "quoteId"?: number;
    "employeeId"?: number;
    "quoteStatus"?: QuoteStatusHistoryQuoteStatusEnum;
    "createdOn"?: Date;
    "employee"?: Employee;
    "quote"?: Quote;
}

export type QuoteStatusHistoryQuoteStatusEnum = "Pending" | "Accepted" | "Declined" | "ApprovalNeeded" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface QuoteStop {
    "id"?: number;
    "quoteId"?: number;
    "shipperStartDate"?: Date;
    "shipperEndDate"?: Date;
    "isShipperAppointmentRequired"?: boolean;
    "consigneeStartDate"?: Date;
    "consigneeEndDate"?: Date;
    "isConsigneeAppointmentRequired"?: boolean;
    "description"?: string;
    "tarpId"?: number;
    "declaredValue"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "shipperHardTime"?: string;
    "consigneeHardTime"?: string;
    "shipmentNotes"?: string;
    "freightBillNumber"?: string;
    "freightBillCreatedOn"?: Date;
    "stopNumber"?: number;
    "isShipperExpedited"?: boolean;
    "isShipperLayover"?: boolean;
    "isConsigneeExpedited"?: boolean;
    "isConsigneeLayover"?: boolean;
    "isMilitaryBase"?: boolean;
    "isTwicCardRequired"?: boolean;
    "shipperContactId"?: number;
    "consigneeContactId"?: number;
    "ponumber"?: string;
    "siteId"?: string;
    "opsCode"?: string;
    "externalNotes"?: string;
    "quote"?: Quote;
    "tarp"?: Tarp;
    "shipperContact"?: CustomerContact;
    "consigneeContact"?: CustomerContact;
    "addresses"?: Array<Address>;
    "quoteFreights"?: Array<QuoteFreight>;
}

export interface QuoteStopFreightQuestion {
    "id"?: number;
    "quoteStopFreightId"?: number;
    "questionId"?: number;
    "answer"?: QuoteStopFreightQuestionAnswerEnum;
    "question"?: Question;
    "quoteFreight"?: QuoteFreight;
}

export type QuoteStopFreightQuestionAnswerEnum = "Yes" | "No" | "NA";
export interface RateModelTest {
    "id"?: number;
    "modelLevelId"?: number;
    "originZipPostalCode"?: string;
    "destZipPostalCode"?: string;
    "datRate"?: number;
    "datRateObject"?: DatRate;
    "commodityId"?: number;
    "companyId"?: number;
    "miles"?: number;
    "defaultHighLineHaulRate"?: number;
    "defaultLowLineHaulRate"?: number;
    "activeHighLineHaulRate"?: number;
    "activeLowLineHaulRate"?: number;
    "futureHighLineHaulRate"?: number;
    "futureLowLineHaulRate"?: number;
    "sandboxHighLineHaulRate"?: number;
    "sandboxLowLineHaulRate"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "minimumAmount"?: number;
    "commodity"?: Commodity;
    "defaultCalculationInfo"?: RateModelTestCalculationInfo;
    "activeCalculationInfo"?: RateModelTestCalculationInfo;
    "futureCalculationInfo"?: RateModelTestCalculationInfo;
    "sandboxCalculationInfo"?: RateModelTestCalculationInfo;
}

export interface RateModelTestCalculationInfo {
    "tssPercent"?: number;
    "ratePerMile"?: number;
    "pricePerFoot"?: number;
    "commodityRate"?: number;
    "capacityRates"?: Array<number>;
}

export interface Region {
    "id"?: number;
    "regionName"?: string;
    "regionAbbreviation"?: string;
    "countryId"?: number;
    "regionZipPostCodes"?: Array<RegionZipPostCode>;
}

export interface RegionZipPostCode {
    "id"?: number;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "zipCodeStart"?: number;
    "zipCodeEnd"?: number;
    "postalCodePrefix"?: string;
}

export interface ReviewSearchCriteria {
    "reviewType"?: ReviewSearchCriteriaReviewTypeEnum;
    "dateType"?: ReviewSearchCriteriaDateTypeEnum;
    "startDate"?: Date;
    "endDate"?: Date;
    "companyId"?: number;
    "reviewedByIds"?: Array<number>;
    "createdByIds"?: Array<number>;
}

export type ReviewSearchCriteriaReviewTypeEnum = "ApprovalsOnly" | "DenialsOnly";
export type ReviewSearchCriteriaDateTypeEnum = "QuoteDate" | "ReviewDate";
export interface SalesHistoryReportParameters {
    "startDate"?: Date;
    "endDate"?: Date;
    "customerRegionId"?: number;
    "accountManagerId"?: number;
}

export interface SimplifiedFullQuote {
    "id"?: number;
    "negotiatedRate"?: number;
    "freightBillNumber"?: string;
}

export interface Tarp {
    "id"?: number;
    "tarpName"?: string;
    "tarpDescription"?: string;
    "isActive"?: boolean;
    "companyId"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "tarpValues"?: Array<TarpValue>;
}

export interface TarpValue {
    "id"?: number;
    "tarpId"?: number;
    "companyId"?: number;
    "rate"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "tarp"?: Tarp;
}

export interface TlorderSalesAgent {
    "salesAgent"?: string;
    "isEnabled"?: boolean;
    "isChecked"?: boolean;
}

export interface TmwinCompanyInfo {
    "id"?: number;
    "name"?: string;
    "isEnabled"?: boolean;
    "isChecked"?: boolean;
}

export interface TmwinTerminal {
    "id"?: number;
    "zone"?: string;
    "desc"?: string;
    "isEnabled"?: boolean;
    "isChecked"?: boolean;
}

export interface TripDetailsLegView {
    "legNumber"?: number;
    "originCity"?: string;
    "originPostalCode"?: string;
    "destinationCity"?: string;
    "destinationPostalCode"?: string;
    "legType"?: string;
    "billNumber"?: string;
    "billDestination"?: string;
    "revenue"?: number;
    "distance"?: number;
    "length"?: number;
    "weight"?: number;
}

export interface Vendor {
    "id"?: number;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "contactName"?: string;
    "createdOn"?: Date;
    "isActive"?: boolean;
    "modifiedOn"?: Date;
    "phoneNumber"?: string;
    "phoneExtension"?: string;
    "emailAddress"?: string;
    "regionId"?: number;
    "tmvendorId"?: string;
    "vendorName"?: string;
    "vendorSince"?: Date;
    "zipPostalCode"?: string;
    "region"?: Region;
}

export interface Zone {
    "id"?: number;
    "zoneName"?: string;
    "primaryZipPostalCode"?: string;
    "secondaryZipPostalCode"?: string;
    "zoneAbbreviation"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "zoneRegions"?: Array<ZoneRegion>;
}

export interface ZoneRegion {
    "id"?: number;
    "zoneId"?: number;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "region"?: Region;
}



/**
 * AboutApi - fetch parameter creator
 */
export const AboutApiFetchParamCreator = {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/about`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AboutApi - functional programming interface
 */
export const AboutApiFp = {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AboutVM> {
        const fetchArgs = AboutApiFetchParamCreator.apiV1AboutGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AboutApi - object-oriented interface
 */
export class AboutApi extends BaseAPI {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any) {
        return AboutApiFp.apiV1AboutGet(options)(this.fetch, this.basePath);
    }
};

/**
 * AboutApi - factory interface
 */
export const AboutApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the about information for the application.
         */
        apiV1AboutGet(options?: any) {
            return AboutApiFp.apiV1AboutGet(options)(fetch, basePath);
        },
    };
};


/**
 * ApplicationSettingsApi - fetch parameter creator
 */
export const ApplicationSettingsApiFetchParamCreator = {
    /** 
     * Get Capacity Report ApplicationSetting entities
     */
    apiV1ApplicationSettingsGetCapacityReportSettingsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/applicationSettings/getCapacityReportSettings`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update an ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAppSettingPost(params: {  "body"?: ApplicationSetting; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/applicationSettings/upsertAppSetting`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ApplicationSettingsApi - functional programming interface
 */
export const ApplicationSettingsApiFp = {
    /** 
     * Get Capacity Report ApplicationSetting entities
     */
    apiV1ApplicationSettingsGetCapacityReportSettingsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApplicationSetting>> {
        const fetchArgs = ApplicationSettingsApiFetchParamCreator.apiV1ApplicationSettingsGetCapacityReportSettingsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update an ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAppSettingPost(params: { "body"?: ApplicationSetting;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApplicationSetting> {
        const fetchArgs = ApplicationSettingsApiFetchParamCreator.apiV1ApplicationSettingsUpsertAppSettingPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ApplicationSettingsApi - object-oriented interface
 */
export class ApplicationSettingsApi extends BaseAPI {
    /** 
     * Get Capacity Report ApplicationSetting entities
     */
    apiV1ApplicationSettingsGetCapacityReportSettingsGet(options?: any) {
        return ApplicationSettingsApiFp.apiV1ApplicationSettingsGetCapacityReportSettingsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Update an ApplicationSetting
     * @param body 
     */
    apiV1ApplicationSettingsUpsertAppSettingPost(params: {  "body"?: ApplicationSetting; }, options?: any) {
        return ApplicationSettingsApiFp.apiV1ApplicationSettingsUpsertAppSettingPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ApplicationSettingsApi - factory interface
 */
export const ApplicationSettingsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get Capacity Report ApplicationSetting entities
         */
        apiV1ApplicationSettingsGetCapacityReportSettingsGet(options?: any) {
            return ApplicationSettingsApiFp.apiV1ApplicationSettingsGetCapacityReportSettingsGet(options)(fetch, basePath);
        },
        /** 
         * Update an ApplicationSetting
         * @param body 
         */
        apiV1ApplicationSettingsUpsertAppSettingPost(params: {  "body"?: ApplicationSetting; }, options?: any) {
            return ApplicationSettingsApiFp.apiV1ApplicationSettingsUpsertAppSettingPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * CapacityReportApi - fetch parameter creator
 */
export const CapacityReportApiFetchParamCreator = {
    /** 
     * Get the capacity report for a given time range
     * @param body Report parameters
     */
    apiV1ReportsCapacityPost(params: {  "body"?: CapacityReportParameters; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/capacity`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CapacityReportApi - functional programming interface
 */
export const CapacityReportApiFp = {
    /** 
     * Get the capacity report for a given time range
     * @param body Report parameters
     */
    apiV1ReportsCapacityPost(params: { "body"?: CapacityReportParameters;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CapacityReport> {
        const fetchArgs = CapacityReportApiFetchParamCreator.apiV1ReportsCapacityPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CapacityReportApi - object-oriented interface
 */
export class CapacityReportApi extends BaseAPI {
    /** 
     * Get the capacity report for a given time range
     * @param body Report parameters
     */
    apiV1ReportsCapacityPost(params: {  "body"?: CapacityReportParameters; }, options?: any) {
        return CapacityReportApiFp.apiV1ReportsCapacityPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CapacityReportApi - factory interface
 */
export const CapacityReportApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get the capacity report for a given time range
         * @param body Report parameters
         */
        apiV1ReportsCapacityPost(params: {  "body"?: CapacityReportParameters; }, options?: any) {
            return CapacityReportApiFp.apiV1ReportsCapacityPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * CompanyApi - fetch parameter creator
 */
export const CompanyApiFetchParamCreator = {
    /** 
     * Get list of companies
     */
    apiV1CompanyGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/company`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CompanyApi - functional programming interface
 */
export const CompanyApiFp = {
    /** 
     * Get list of companies
     */
    apiV1CompanyGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Company>> {
        const fetchArgs = CompanyApiFetchParamCreator.apiV1CompanyGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CompanyApi - object-oriented interface
 */
export class CompanyApi extends BaseAPI {
    /** 
     * Get list of companies
     */
    apiV1CompanyGet(options?: any) {
        return CompanyApiFp.apiV1CompanyGet(options)(this.fetch, this.basePath);
    }
};

/**
 * CompanyApi - factory interface
 */
export const CompanyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get list of companies
         */
        apiV1CompanyGet(options?: any) {
            return CompanyApiFp.apiV1CompanyGet(options)(fetch, basePath);
        },
    };
};


/**
 * EmployeeApi - fetch parameter creator
 */
export const EmployeeApiFetchParamCreator = {
    /** 
     * Get all employees
     * @param body 
     */
    searchEmployees(params: {  "body"?: EmployeeSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/employees`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * EmployeeApi - functional programming interface
 */
export const EmployeeApiFp = {
    /** 
     * Get all employees
     * @param body 
     */
    searchEmployees(params: { "body"?: EmployeeSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Employee>> {
        const fetchArgs = EmployeeApiFetchParamCreator.searchEmployees(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * EmployeeApi - object-oriented interface
 */
export class EmployeeApi extends BaseAPI {
    /** 
     * Get all employees
     * @param body 
     */
    searchEmployees(params: {  "body"?: EmployeeSearchCriteria; }, options?: any) {
        return EmployeeApiFp.searchEmployees(params, options)(this.fetch, this.basePath);
    }
};

/**
 * EmployeeApi - factory interface
 */
export const EmployeeApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get all employees
         * @param body 
         */
        searchEmployees(params: {  "body"?: EmployeeSearchCriteria; }, options?: any) {
            return EmployeeApiFp.searchEmployees(params, options)(fetch, basePath);
        },
    };
};


/**
 * RegionApi - fetch parameter creator
 */
export const RegionApiFetchParamCreator = {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/region`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RegionApi - functional programming interface
 */
export const RegionApiFp = {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Region>> {
        const fetchArgs = RegionApiFetchParamCreator.apiV1RegionGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RegionApi - object-oriented interface
 */
export class RegionApi extends BaseAPI {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any) {
        return RegionApiFp.apiV1RegionGet(options)(this.fetch, this.basePath);
    }
};

/**
 * RegionApi - factory interface
 */
export const RegionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of Regions
         */
        apiV1RegionGet(options?: any) {
            return RegionApiFp.apiV1RegionGet(options)(fetch, basePath);
        },
    };
};


/**
 * ReportApi - fetch parameter creator
 */
export const ReportApiFetchParamCreator = {
    /** 
     * Filter parameters for Booked Sales report (sourced from TruckMate)
     */
    getBookedSalesFilters(options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/bookedSalesFilters`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Booked Sales report
     * @param body 
     */
    getBookedSalesReport(params: {  "body"?: BookedSalesParametersVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/bookedSalesReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delivered Freight report
     * @param body 
     */
    getDeliveredFreightReport(params: {  "body"?: DeliveredFreightSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/deliveredFreightReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Lane Revenue Analysis report
     * @param body 
     */
    getLaneRevenueReport(params: {  "body"?: LaneRevenueParametersVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/laneRevenueReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    getQuoteConversionReport(params: {  "body"?: ConversionSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/quoteConversionReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    getQuoteReviewsReport(params: {  "body"?: ReviewSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/quoteReviewsReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    getSalesHistoryReport(params: {  "body"?: SalesHistoryReportParameters; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/salesHistoryReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    getSalesOpportunitiesReport(params: {  "body"?: OpportunitySearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/reports/salesOpportunitiesReport`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Trip Details for the given trip number
     * @param tripNumber 
     */
    getTripDetails(params: {  "tripNumber": number; }, options?: any): FetchArgs {
        // verify required parameter "tripNumber" is set
        if (params["tripNumber"] == null) {
            throw new Error("Missing required parameter tripNumber when calling getTripDetails");
        }
        const baseUrl = `/api/v1/reports/tripDetails/{tripNumber}`
            .replace(`{${"tripNumber"}}`, `${ params["tripNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ReportApi - functional programming interface
 */
export const ReportApiFp = {
    /** 
     * Filter parameters for Booked Sales report (sourced from TruckMate)
     */
    getBookedSalesFilters(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BookedSalesFilters> {
        const fetchArgs = ReportApiFetchParamCreator.getBookedSalesFilters(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Booked Sales report
     * @param body 
     */
    getBookedSalesReport(params: { "body"?: BookedSalesParametersVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BookedSalesReport> {
        const fetchArgs = ReportApiFetchParamCreator.getBookedSalesReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delivered Freight report
     * @param body 
     */
    getDeliveredFreightReport(params: { "body"?: DeliveredFreightSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveredFreightReport> {
        const fetchArgs = ReportApiFetchParamCreator.getDeliveredFreightReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Lane Revenue Analysis report
     * @param body 
     */
    getLaneRevenueReport(params: { "body"?: LaneRevenueParametersVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LaneRevenueReportView>> {
        const fetchArgs = ReportApiFetchParamCreator.getLaneRevenueReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    getQuoteConversionReport(params: { "body"?: ConversionSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuoteConversionReportRow>> {
        const fetchArgs = ReportApiFetchParamCreator.getQuoteConversionReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    getQuoteReviewsReport(params: { "body"?: ReviewSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<QuoteReviewsReportRow>> {
        const fetchArgs = ReportApiFetchParamCreator.getQuoteReviewsReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    getSalesHistoryReport(params: { "body"?: SalesHistoryReportParameters;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Customer>> {
        const fetchArgs = ReportApiFetchParamCreator.getSalesHistoryReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    getSalesOpportunitiesReport(params: { "body"?: OpportunitySearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Opportunity>> {
        const fetchArgs = ReportApiFetchParamCreator.getSalesOpportunitiesReport(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Trip Details for the given trip number
     * @param tripNumber 
     */
    getTripDetails(params: { "tripNumber": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TripDetailsLegView>> {
        const fetchArgs = ReportApiFetchParamCreator.getTripDetails(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ReportApi - object-oriented interface
 */
export class ReportApi extends BaseAPI {
    /** 
     * Filter parameters for Booked Sales report (sourced from TruckMate)
     */
    getBookedSalesFilters(options?: any) {
        return ReportApiFp.getBookedSalesFilters(options)(this.fetch, this.basePath);
    }
    /** 
     * Booked Sales report
     * @param body 
     */
    getBookedSalesReport(params: {  "body"?: BookedSalesParametersVM; }, options?: any) {
        return ReportApiFp.getBookedSalesReport(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delivered Freight report
     * @param body 
     */
    getDeliveredFreightReport(params: {  "body"?: DeliveredFreightSearchCriteria; }, options?: any) {
        return ReportApiFp.getDeliveredFreightReport(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Lane Revenue Analysis report
     * @param body 
     */
    getLaneRevenueReport(params: {  "body"?: LaneRevenueParametersVM; }, options?: any) {
        return ReportApiFp.getLaneRevenueReport(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    getQuoteConversionReport(params: {  "body"?: ConversionSearchCriteria; }, options?: any) {
        return ReportApiFp.getQuoteConversionReport(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    getQuoteReviewsReport(params: {  "body"?: ReviewSearchCriteria; }, options?: any) {
        return ReportApiFp.getQuoteReviewsReport(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    getSalesHistoryReport(params: {  "body"?: SalesHistoryReportParameters; }, options?: any) {
        return ReportApiFp.getSalesHistoryReport(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    getSalesOpportunitiesReport(params: {  "body"?: OpportunitySearchCriteria; }, options?: any) {
        return ReportApiFp.getSalesOpportunitiesReport(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Trip Details for the given trip number
     * @param tripNumber 
     */
    getTripDetails(params: {  "tripNumber": number; }, options?: any) {
        return ReportApiFp.getTripDetails(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ReportApi - factory interface
 */
export const ReportApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Filter parameters for Booked Sales report (sourced from TruckMate)
         */
        getBookedSalesFilters(options?: any) {
            return ReportApiFp.getBookedSalesFilters(options)(fetch, basePath);
        },
        /** 
         * Booked Sales report
         * @param body 
         */
        getBookedSalesReport(params: {  "body"?: BookedSalesParametersVM; }, options?: any) {
            return ReportApiFp.getBookedSalesReport(params, options)(fetch, basePath);
        },
        /** 
         * Delivered Freight report
         * @param body 
         */
        getDeliveredFreightReport(params: {  "body"?: DeliveredFreightSearchCriteria; }, options?: any) {
            return ReportApiFp.getDeliveredFreightReport(params, options)(fetch, basePath);
        },
        /** 
         * Lane Revenue Analysis report
         * @param body 
         */
        getLaneRevenueReport(params: {  "body"?: LaneRevenueParametersVM; }, options?: any) {
            return ReportApiFp.getLaneRevenueReport(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        getQuoteConversionReport(params: {  "body"?: ConversionSearchCriteria; }, options?: any) {
            return ReportApiFp.getQuoteConversionReport(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        getQuoteReviewsReport(params: {  "body"?: ReviewSearchCriteria; }, options?: any) {
            return ReportApiFp.getQuoteReviewsReport(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        getSalesHistoryReport(params: {  "body"?: SalesHistoryReportParameters; }, options?: any) {
            return ReportApiFp.getSalesHistoryReport(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        getSalesOpportunitiesReport(params: {  "body"?: OpportunitySearchCriteria; }, options?: any) {
            return ReportApiFp.getSalesOpportunitiesReport(params, options)(fetch, basePath);
        },
        /** 
         * Trip Details for the given trip number
         * @param tripNumber 
         */
        getTripDetails(params: {  "tripNumber": number; }, options?: any) {
            return ReportApiFp.getTripDetails(params, options)(fetch, basePath);
        },
    };
};
